// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_appsync_graphqlEndpoint": "https://gytaeen52badldj4nfvqng5s4q.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    // "aws_appsync_apiKey": "da2-fw2ymemhhzhijoc7aopbroqnvy",
    "Auth": {
        "region": "eu-central-1",
        "userPoolId": "eu-central-1_vNgnq1ws9",
        "userPoolWebClientId": "2fpe10sitjdsrfsu39dgkhj677",
      },
};

export default awsmobile;